import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AdminService {
  private adminDataSource = new BehaviorSubject({});
  adminData = this.adminDataSource.asObservable();
  user: any;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private authService: AuthenticationService
  ) {
    this.authService.user.subscribe(value => (this.user = value));
  }

  //Person API
  getAllPersons(): Observable<any> {
    return this.http.get('/services/persons/allperson');
  }

  getUserPermissions(userID): Observable<any> {
    return this.http.get(`/services/persons/rolepermission/${userID}`);
  }
  saveUserGeneralDetails(type, person): Observable<any> {
    return this.http.post(`/services/persons/${type}/${this.user.username}`, { person });
  }
  updateUserPermission(requestBody, userID): Observable<any> {
    return this.http.put(`/services/persons/update/rolepermission/${userID}`, requestBody);
  }
  //Issuer API
  getAllIssuers(): Observable<any>{
    return this.http.get('/services/issuers/admin/allissuer');
  }
  getIssuer(issuer): Observable<any>{
    return this.http.get(`/services/issuers/${issuer}`);
  }
  getAllExchanges(): Observable<any>{
    return this.http.get('/services/issuers/exchanges');
  }
  getIssuerExhange(issuerID): Observable<any>{
    return this.http.get(`/services/issuers/exchanges/${issuerID}`);
  }
  saveIssuer(action, requestBody): Observable<any> {
    return this.http.post(`/services/issuers/${action}/${this.user.username}`, requestBody);
  }
  // Distribution API
  getAllDistributions(): Observable<any> {
    return this.http.get('/services/distribution/alldocumentTypes');
  }
  getDistribution(id: any): Observable<any> {
    return this.http.get(`/services/distribution/documentdistributions/${id}`);
  }
  saveDistributorRecipients(id, payload): Observable<any>  {
    return this.http.post(`/services/distribution/updatedocumentdistributions/${id}`, payload);
  }
  //Industry API
  saveIndustry(path, industry): Observable<any>  {
    return this.http.post(`/services/industry/${path}/${this.user.username}`, { industry });
  }
  getAllIndustries(): Observable<any> {
    return this.http.get('/services/industry/admin/allindustries');
  }
  //Report API
  getAllReports(type): Observable<any> {
    return this.http.get(`/services/admin/${type}/list?type=all`);
  }
  saveReport(type, data): Observable<any> {
    return this.http.put(`/services/admin/${type}/update/${this.user.username}`, data);
  }
  //Document API
  downloadReport(type):Observable<any> {
    return this.http.get(`/services/documents/${type}`, { responseType: 'arraybuffer', observe: 'response' });
  }

  ratingsDistribution(action, requestBody): Observable<any> {
    return this.http.post(`/services/admin/ratingdistributions/${action}`, requestBody);
  }
}
